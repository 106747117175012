import { version } from "../package.json";
import fs from "fs";
import isbot from "isbot";
export const strict = false;
import gr from "greek-utils";
// but will be deprecated in the next major version.
let redirectCheck = process.env.REDIRECT_CHECK;
const parser = require("ua-parser-js");
let cartLock = false;
const safeIncludes = (v, q) => {
  return (v + "" || "").includes(q);
};

export const state = () => ({
  host: "",
  isBot: false,
  attributions: "",
  dataDump: {},
  elementData: {},
  params: {},
  isMobile: false,
  isBackButton: false,
  lockOpen: {},
  isTouch: false,
  isIOS: false,
  isMozilla: false,
  readyness: { googleMapsAutocomplete: false },
  windowOffset: 0,
  scrollingDirection: null,
  menuActive: "home",
  actual: {},
  location: {},
  cartFocus: false,
  route: "",
  url: "",
  query: {},
  sub: {},
  cms: {},
  addToCartItem: {},
  elementEventPool: {},
  contact: {
    item: false,
    open: false,
    title: "",
  },
  timers: {},
  notTags: [
    "business_activity",
    "family",
    "subfamily",
    "category",
    "manufacturer",
    "persons",
    "weight",
    "limit",
    "q",
    "page",
    "pages",
    "total",
    "sorting",
    "sort",
    "orderby",
    "order",
  ],
  store: {
    store_id: "",
    name: "Loading",
    phone: "",
    email: "info@loading.gr",
    address: "Ηρώων πολυτεχνείου 22, Καρδίτσα 43100",
    promoMessage: "Καλώς ήλθατε!",
    logo: "",
    negativeLogo: "",
    legalNameFooter: "loading",
    payments: [
      {
        type: "cod",
        cost: 0,
        title: "Αντικαταβολή",
      },
    ],
    shippings: [
      {
        type: "pickup",
        cost: 0,
        title: "Παραλαβή από το κατάστημα",
      },
    ],
    parasts: [
      {
        type: "receipt",
        title: "Απόδειξη Λιανικής",
        cost: 0,
      },
      {
        type: "invoice",
        title: "Τιμολόγιο",
        cost: 0,
      },
    ],
    whyUs: [
      {
        title: "",
        text: "",
        icon: "",
      },
      {
        title: "",
        text: "",
        icon: "",
      },
      {
        title: "",
        text: "",
        icon: "",
      },
    ],
    services: [
      {
        title: "",
        text: "",
        icon: "",
      },
      {
        title: "",
        text: "",
        icon: "",
      },
      {
        title: "",
        text: "",
        icon: "",
      },
    ],
  },
  cart: {
    items: [],
    totalQ: 0,
    totalC: 0,
    sum: 0,
    step: 0,
    shipping: {
      cost: 0,
      address: {},
    },
    shipping2: {
      address: "",
      town: "",
      postal_code: "",
      country: "",
      floor: "",
      phone: "",
      first_name: "",
      last_name: "",
    },
    billingAddress: false,
    services: [],
    payment: {
      cost: 0,
    },
    parast: {
      type: "receipt",
    },
    user: {},
    checkout: "guest",
  },
  afterCart: {},
  sidePanel: {},
  filterTree: [],
  linkedMediaDefaults: {
    default: "/template/default/light/InterfaceandSign/list-alt-1.svg",
    business_activity:
      "/template/default/light/InterfaceandSign/list-alt-1.svg",
    family: "/template/default/light/InterfaceandSign/list-alt-1.svg",
    subfamily: "/template/default/light/InterfaceandSign/list-alt-1.svg",
    category: "/template/default/light/InterfaceandSign/list-alt-1.svg",
    manufacturer: "/template/default/light/Building/home-alt-12.svg",
    persons: "/template/default/light/User/user.svg",
  },
  filterLabels: {},
  labels: {
    cart: "Καλάθι",
    toCart: "Αγορά",
    inCart: "Βρίσκεται στο καλάθι",
    login: "Είσοδος",
    logout: "Έξοδος",
    contact: "Επικοινωνία",
    phone: "Τηλέφωνο",
    notify: "Ειδοποίησε με",
    orderPhone: "Τηλέφωνο παραγγελιών",
    manufacturers: "Κατασκευαστές/Εκδότες",
    manufacturer: "Κατασκευαστής/Εκδότης",
    account: "Λογαριασμός",
    menu: "Menu",
    business_activity: "Δραστηριότητα",
    family: "Οικογένεια",
    subfamily: "Υποοικογένεια",
    category: "Κατηγορία",
    speechExample: "",
    "tags.persons": "Συντελεστές",
    persons: "Συντελεστές",
    "availability.status": "Διαθεσιμότητα",
    prices: "Τιμές",
  },
  storeMemory: [],
  token: undefined,
  dashboard: false,
  user: {},
  item: {
    images: [],
    prices: {},
  },
  itemReady: false,
  items: [],
  expanded: false,
  pagination: [],
  sideFilters: {},
  filters: {},
  catalogue: {},
  optionsRequired: {},
  loading: false,
  analytics: {
    skroutz: false,
    google: false,
    facebook: false,
  },
  distinct: {
    labels: {
      manufacturer: "Κατασκευαστές/Εκδότες",
      family: "Οικογένειες",
      "tags.series": "Σειρές",
      "tags.persons": "Συγγραφείς",
      "tags.Σειρά": "Σειρά",
    },
    icons: {
      manufacturer: "ic-user-alt",
      family: "ic-list-alt",
    },
  },
  notifications: false,
  listening: false,
  said: "",
  annyang: false,
  history: {},
  clientLoaded: false,
});

export const mutations = {
  initialiseStore(state) {
    if (process.browser) {
      if (localStorage.getItem("state")) {
        let temp = JSON.parse(localStorage.getItem("state"));

        if (temp.version == version) {
          this.replaceState(
            Object.assign(state, {
              cart: temp.cart,
              history: temp.history,
            })
          );
        } else {
          state.version = version;
        }
      }
    }
  },
  binder(state, payload) {
    try {
      eval(`state.${payload.path}='${payload.value}'`);
      if (payload.path.includes("item.")) {
        $nuxt.$emit("updateItem", state.item);
      }
    } catch (error) {
      console.error(error);
    }
  },
  elementEventPool(state, payload) {
    if (typeof payload == "object") {
      state.elementEventPool[payload.key] = payload.value;
      return;
    }
    state.elementEventPool = payload;
  },
  host(state, payload) {
    state.host = payload;
  },
  isBot(state, payload) {
    state.isBot = payload;
  },
  attribution(state, payload) {
    state.attribution = payload;
  },
  elementData(state, payload) {
    state.elementData[payload.key] = payload.value;
  },
  dataDump(state, payload) {
    state.dataDump[payload.key] = payload.value;
  },
  params(state, payload) {
    state.params = payload;
  },
  clientLoaded(state, payload) {
    state.clientLoaded = payload;
  },
  readyness(state, payload) {
    state.readyness[payload.key] = payload.value;
  },
  timers(state, payload) {
    state.timers[payload.input] = payload;
  },
  isMobile(state, payload) {
    state.isMobile = payload;
  },
  isBackButton(state, payload) {
    state.isBackButton = payload;
  },
  lockOpen(state, payload) {
    state.lockOpen[payload.key] = payload.value;
  },
  isTouch(state, payload) {
    state.isTouch = payload;
  },
  isIOS(state, payload) {
    state.isIOS = payload;
  },
  isMozilla(state, payload) {
    state.isMozilla = payload;
  },
  windowOffset(state, payload) {
    state.windowOffset = payload;
  },
  scrollingDirection(state, payload) {
    state.scrollingDirection = payload;
  },
  location(state, payload) {
    state.location = payload;
  },
  cartFocus(state, payload) {
    state.cartFocus = payload;
  },
  notTags(state, payload) {
    state.notTags = payload;
  },
  notifications(state, payload) {
    state.notifications = payload;
  },
  menuActive(state, payload) {
    state.menuActive = payload;
  },
  cms(state, payload) {
    state.cms[payload.key] = payload.data;
  },
  url(state, payload) {
    state.url = payload;
  },
  route(state, payload) {
    state.route = payload;
  },
  query(state, payload) {
    state.query = payload;
  },
  contact(state, payload) {
    state.contact = payload;
  },
  store(state, payload) {
    if (payload.key) {
      state.store[payload.key] = payload.value;
      return;
    }
    state.store = payload;
  },
  eshop(state, payload) {
    if (payload.key && state.store.eshop) {
      state.store.eshop[payload.key] = payload.value;
      return;
    }
    state.store.eshop = payload;
  },
  store_id(state, payload) {
    state.store.store_id = payload;
  },
  elements(state, payload) {
    state.store.eshop.elements = payload;
  },
  var(state, payload) {
    state.store.eshop.var[payload.key] = payload.value;
  },
  actual(state, payload) {
    state.actual = payload;
  },
  cart(state, payload) {
    state.cart = payload;
  },
  afterCart(state, payload) {
    state.afterCart = payload;
  },
  labels(state, payload) {
    state.labels = {
      ...state.labels,
      ...payload,
    };
  },
  filterLabels(state, payload) {
    if (payload.key) {
      state.filterLabels[payload.key] = payload.value;
      return;
    }
  },
  token(state, payload) {
    state.token = payload;
  },
  dashboard(state, payload) {
    state.dashboard = payload;
  },
  user(state, payload) {
    state.user = payload;
  },
  filterTree(state, payload) {
    state.filterTree = payload;
  },
  sideFilters(state, payload) {
    state.sideFilters = payload;
  },
  filters(state, payload) {
    state.filters = Object.keys(payload).reduce((acc, f) => {
      if (payload[f] && payload[f] != "-") {
        acc[f] = payload[f];
      }
      return acc;
    }, {});
  },
  catalogue(state, payload) {
    state.catalogue = payload;
  },
  storeMemory(state, payload) {
    state.storeMemory = payload;
  },
  addToCartItem(state, payload) {
    state.addToCartItem = payload;
  },
  item(state, payload) {
    state.item = payload;
  },
  itemReady(state, payload) {
    state.itemReady = payload;
  },
  optionsRequired(state, payload) {
    state.optionsRequired = payload;
  },
  expanded(state, payload) {
    state.expanded = payload;
  },
  sub(state, payload) {
    state.sub = payload;
  },
  sidePanel(state, payload) {
    state.sidePanel = payload;
  },
  items(state, payload) {
    state.items = payload;
  },
  pagination(state, payload) {
    state.pagination = payload;
  },
  analytics(state, payload) {
    state.analytics[payload] = true;
  },
  loading(state, payload) {
    state.loading = payload;
  },
  distinct(state, payload) {
    if (payload.clear) {
      state.distinct = {
        ...{
          labels: state.distinct.labels,
          icons: state.distinct.icons,
        },
      };
      return;
    }
    if (payload.property) {
      state.distinct = {
        ...state.distinct,
        [payload.property]: payload.data,
      };
      return;
    }
  },
  listening(state, payload) {
    state.listening = payload;
  },
  said(state, payload) {
    state.said = payload;
  },
  annyang(state, payload) {
    state.annyang = payload;
  },
  history(state, payload) {
    //better safe than sorry
    try {
      if (payload.type == "item") {
        state.history.items = (state.history.items || []).filter(
          (i) => i.barcode != (payload.item || {}).barcode
        );
        state.history.items.unshift({ ...payload.item, comment: "" });
        if (state.history.items.length > 10) {
          state.history.items.pop();
        }
      } else if (payload.type == "search") {
        state.history.search = (state.history.search || []).filter(
          (s) => s != payload.search
        );
        state.history.search.push(payload.search);
      } else {
        state.history = payload;
      }
      if (process.browser) {
        localStorage.setItem("history", JSON.stringify(state.history));
      }
    } catch (e) {
      console.error(e);
    }
  },
};

export const getters = {
  host(state) {
    return state.host;
  },
  isBot(state) {
    return state.isBot;
  },
  attribution(state) {
    return state.attribution;
  },
  elementData(state) {
    return state.elementData;
  },
  params(state) {
    return state.params;
  },
  dataDump(state) {
    return state.dataDump;
  },
  isMobile(state) {
    return state.isMobile;
  },
  isBackButton(state) {
    return state.isBackButton;
  },
  lockOpen: (state) => (key) => state.lockOpen[key],
  isTouch(state) {
    return state.isTouch;
  },
  isIOS(state) {
    return state.isIOS;
  },
  isMozilla(state) {
    return state.isMozilla;
  },
  windowOffset(state) {
    return state.windowOffset;
  },
  scrollingDirection(state) {
    return state.scrollingDirection;
  },
  linkedMediaDefaults(state) {
    return state.linkedMediaDefaults;
  },
  location(state) {
    return state.location;
  },
  cartFocus(state) {
    return state.cartFocus;
  },
  timers(state) {
    return state.timers;
  },
  elementEventPool(state) {
    return state.elementEventPool;
  },
  clientLoaded(state) {
    return state.clientLoaded;
  },
  readyness(state) {
    return state.readyness;
  },
  notTags(state) {
    return state.notTags;
  },
  notifications(state) {
    return state.notifications;
  },
  menuActive(state) {
    return state.menuActive;
  },
  cms(state) {
    return state.cms;
  },
  route(state) {
    return state.route;
  },
  url(state) {
    return state.url;
  },
  query(state) {
    return state.query;
  },
  contact(state) {
    return state.contact;
  },
  store(state) {
    return state.store;
  },
  elements(state) {
    return state.store.eshop.elements;
  },
  actual(state) {
    return state.actual;
  },
  cart(state) {
    return state.cart;
  },
  afterCart(state) {
    return state.afterCart;
  },
  labels(state) {
    return state.labels;
  },
  filterLabels(state) {
    return state.filterLabels;
  },
  token(state) {
    return state.token;
  },
  dashboard(state) {
    return state.dashboard;
  },
  storeMemory(state) {
    return state.storeMemory;
  },
  user(state) {
    return state.user;
  },
  filterTree(state) {
    return state.filterTree;
  },
  filters(state) {
    return state.filters;
  },
  sideFilters(state) {
    return state.sideFilters;
  },
  catalogue(state) {
    return state.catalogue;
  },
  addToCartItem(state) {
    return state.addToCartItem;
  },
  item(state) {
    return state.item;
  },
  itemReady(state) {
    return state.itemReady;
  },
  optionsRequired(state) {
    return state.optionsRequired;
  },
  expanded(state) {
    return state.expanded;
  },
  sub(state) {
    return state.sub;
  },
  sidePanel(state) {
    return state.sidePanel;
  },
  items(state) {
    return state.items;
  },
  pagination(state) {
    return state.pagination;
  },
  analytics(state) {
    return state.analytics;
  },
  loading(state) {
    return state.loading;
  },
  distinct(state) {
    return state.distinct;
  },
  listening(state) {
    return state.listening;
  },
  said(state) {
    return state.said;
  },
  annyang(state) {
    return state.annyang;
  },
  history(state) {
    return state.history;
  },
};

function escapeHTML(s) {
  return s
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

function prepareComps(string) {
  return (string || "")
    .split("_")
    .map((s) => {
      s = s.charAt(0).toUpperCase() + s.slice(1);
      return s;
    })
    .join("");
}
let actualRender = (req, res, sconfig) => {
  let actual = {};
  // console.table(sconfig);
  if (sconfig) {
    actual = {
      index: `${prepareComps(res.eshop.layout.index)}`,
      items: `${prepareComps(res.eshop.layout.items)}`,
      itemsElastic: `${prepareComps(res.eshop.layout.itemsElastic)}`,
      item: `${prepareComps(res.eshop.layout.item)}`,
      navBar: `${prepareComps(res.eshop.layout.navBar)}`,
      header: `${prepareComps(res.eshop.layout.header)}`,
      footer: `${prepareComps(res.eshop.layout.footer)}`,
      mobile: `${prepareComps(res.eshop.layout.mobile)}`,
      account: `${prepareComps(res.eshop.layout.account || "default")}`,
      cart: `${prepareComps(res.eshop.layout.cart || "cart")}`,
      product: `${prepareComps(res.eshop.layout.product || "product_book")}`,
      search: `${prepareComps(res.eshop.layout.search)}`,
      success: `${prepareComps(res.eshop.layout.success)}`,
      blog: `${prepareComps(res.eshop.layout.blog) || "BlogDefaultBlog"}`,
      article: `${prepareComps(res.eshop.layout.article) || "article"}`,
      track: `${prepareComps(res.eshop.layout.track) || "TrackDefault"}`,
      brands: `${prepareComps(res.eshop.layout.brands) || "BrandsDefault"}`,
      error: `${prepareComps(res.eshop.layout.error) || "ErrorDefault"}`,
      specific: `${prepareComps(res.eshop.layout.specific) || ""}`,
    };
  } else {
    actual = {
      index: `Index${prepareComps(res.eshop.layout.index)}`,
      items: `${prepareComps(res.eshop.layout.items)}`,
      itemsElastic: `ItemsElastic${prepareComps(
        res.eshop.layout.itemsElastic
      )}`,
      item: `${prepareComps(res.eshop.layout.item)}`,
      navBar: `NavBar${prepareComps(res.eshop.layout.navBar)}`,
      header: `${prepareComps(res.eshop.layout.header)}`,
      footer: `${prepareComps(res.eshop.layout.footer)}`,
      mobile: `${prepareComps(res.eshop.layout.mobile)}`,
      account: `Account${prepareComps(res.eshop.layout.account || "default")}`,
      cart: `${prepareComps(res.eshop.layout.cart || "cart")}`,
      product: `${prepareComps(res.eshop.layout.product || "product_book")}`,
      blog: `${prepareComps(res.eshop.layout.blog) || "BlogDefault"}`,
      article: `${prepareComps(res.eshop.layout.article) || "ArticleDefault"}`,
      track: `${prepareComps(res.eshop.layout.track) || "TrackDefault"}`,
      brands: `${prepareComps(res.eshop.layout.brands) || "BrandsDefault"}`,
      error: `${prepareComps(res.eshop.layout.error) || "ErrorDefault"}`,
      specific: `${prepareComps(res.eshop.layout.specific) || ""}`,
    };
  }
  return actual;
};

let mergePanels = async (commit, state, incoming, ba, sfilter) => {
  if (incoming) {
    Object.keys(incoming).forEach((k) => {
      incoming[k] = incoming[k].sort((a, b) =>
        (a || "").toString().localeCompare(b)
      );
    });
  }
  let sidePanel = JSON.parse(JSON.stringify(state.sidePanel));
  if (incoming["tags.persons"]) {
    incoming["tags.persons"] = [...new Set(incoming["tags.persons"].flat(2))];
  }
  if (!Object.keys(sidePanel).length) {
    let final = {};
    Object.keys(incoming).forEach((k) => {
      let key = k;
      let tkey;
      if (k.includes("tags")) {
        key = "tag_value";
        tkey = "tag_key";
      }
      if (!incoming[k].length) {
        return;
      }
      final[k] = {
        all: incoming[k],
        active:
          k.replace("tags.", "") == state.sideFilters[tkey]
            ? (state.sideFilters[key] || "")
                .split("_or_")
                .filter((f) => f != "")
            : [],
      };
    });
    commit("sidePanel", final);
    return;
  }
  let totalKeys = [
    ...new Set([
      ...Object.keys(sidePanel),
      ...Object.keys(sidePanel).filter(
        (sp) => !Object.keys(incoming).includes(sp)
      ),
    ]),
  ];
  Object.keys(incoming).forEach((k) => {
    if (!incoming[k][0] && sidePanel[k] && sidePanel[k][0]) {
      sidePanel[k].all = [];
      sidePanel[k].active = [];
      sidePanel[k].inactive = [];
    }
    if (!sidePanel.hasOwnProperty(k)) {
      sidePanel[k] = {
        all: [],
        active: [],
        inactive: [],
      };
      //auta poy perisepsan den tairiazoyn me ta nea
    }
  });
  Object.keys(sidePanel).forEach((k) => {
    if (state.sideFilters[k] == "") {
      sidePanel[k].active = [];
    }
    let key = k == "tags.author" ? "person" : k;
    sidePanel[k].inactive = [];
    if (incoming[k] && sidePanel[k] && sidePanel[k].all) {
      sidePanel[k].all = [...new Set([...sidePanel[k].all, ...incoming[k]])];
      sidePanel[k].active = [
        ...new Set([
          ...sidePanel[k].active,
          ...(state.sideFilters[key] || "")
            .split("_or_")
            .filter((f) => f != ""),
        ]),
      ];
      sidePanel[k].all.forEach((a) => {
        if (
          !incoming[k].includes(a) &&
          (incoming[k] || []).length &&
          sidePanel[k].active &&
          !sidePanel[k].active.length
        ) {
          sidePanel[k].inactive = [...sidePanel[k].inactive, a];
        }
      });
      let active = sidePanel[k].all.filter(
        (f) => !sidePanel[k].inactive.includes(f)
      );
      sidePanel[k].all = [...active, ...sidePanel[k].inactive];
      // sidePanel[k].inactive = sidePanel[k].all.filter(x => !sidePanel[k].active.includes(x));
    } else {
      sidePanel[k].inactive = true;
    }
  });
  commit("sidePanel", sidePanel);
};

export const actions = {
  async filters({ commit, state, route }, ctx) {
    try {
      let aggs = state.store.eshop.filtersAggs.map((a) => a.key) || [];
      let pushed = false;
      // let pushed =true;
      // aggs.push('business_activity');
      // aggs.push('family');
      // aggs.push('subfamily');
      let keys = [];
      let filtersPool = {};
      if (!ctx) {
        ctx = {};
      }
      if (ctx.external) {
        keys = Object.keys(ctx.external);
        filtersPool = ctx.external;
        if (ctx.aggs) {
          aggs = ctx.aggs;
        }
      } else {
        keys = Object.keys(state.filters);
        filtersPool = state.filters;
      }
      if (
        !keys.includes("business_activity") &&
        !keys.includes("family") &&
        !keys.includes("subfamily")
      ) {
        aggs.unshift("business_activity");
        pushed = true;
      }
      if (!pushed && !keys.includes("family") && !keys.includes("subfamily")) {
        aggs.unshift("family");
        pushed = true;
      }
      if (!pushed && !keys.includes("subfamily")) {
        aggs.unshift("subfamily");
        pushed = true;
      }
      if (!filtersPool.category && !pushed) {
        aggs.unshift("category");
        pushed = true;
      }
      let size = 10;
      if ((ctx || {}).size) {
        size = ctx.size;
      }
      if ((ctx || {}).aggs) {
        aggs = ctx.aggs;
      }
      let partial = undefined;
      if ((ctx || {}).partial) {
        partial = true;
      }
      try {
        let filters = Object.keys(filtersPool).reduce((acc, k) => {
          if (filtersPool[k] != "-" && filtersPool[k]) {
            acc[k] = filtersPool[k];
          }
          return acc;
        }, {});
        if (!filters.price) {
          filters.price = "0-99999";
        }
        delete filters.limit;
        let temp = await this.$axios.$post(`items/sv/filters`, {
          store_id: state.store.store_id,
          ...filters,
          aggs,
          size,
          partial,
          settings: {
            tagsMultiple: state.store.eshop.tagsMultiple,
            catalogRoute: state.store.eshop.catalogRoute,
            filters: state.filters,
          },
        });
        if ((ctx || {}).partial) {
          return temp;
        }
        let reserved = [
          "business_activity",
          "family",
          "subfamily",
          "category",
          "manufacturer",
          "persons",
          "weight",
        ];
        let filtersExclude = (
          (state.store.eshop.filtersExclude || "").split(",") || []
        ).map((k) => (reserved.includes(k) ? k : "tag." + k));
        let filtersSort = (
          (state.store.eshop.filtersSort || "").split(",") || []
        ).map((k) => (reserved.includes(k) ? k : "tag." + k));
        let rs = (Object.keys(temp) || []).reduce((acc, k) => {
          let f = {
            order: filtersSort.indexOf(k) > -1 ? filtersSort.indexOf(k) : 9999,
            tag: !reserved.includes(k),
            [k]: temp[k],
            key: k,
          };
          if (!filtersExclude.includes(k)) {
            acc.push(f);
          }
          return acc;
        }, []);
        rs.sort((a, b) => a.order - b.order);
        if (ctx.external) {
          return rs;
        }
        commit("catalogue", rs);
      } catch (error) {
        console.error(error);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getAutoCompletes({ state, commit }, load) {
    let actives = ``;
    let payload = {
      store_id: state.store._id,
      filtered: 1,
      exclude: state.store.eshop.filters
        ? state.store.eshop.filters
        : "isbn,weight,dimensions,release_date,pages,cover",
      properties: `${load.properties}`,
      hidden: "false",
      filter: "false",
    };
    if (state.sideFilters.q && state.sideFilters.q != "") {
      payload.q = state.sideFilters.q;
    }
    if (
      state.sideFilters.business_activity &&
      state.sideFilters.business_activity != ""
    ) {
      payload.business_activity = state.sideFilters.business_activity;
    }
    if (state.sideFilters.family && state.sideFilters.family != "") {
      payload.family = state.sideFilters.family;
    }
    if (state.sideFilters.subfamily && state.sideFilters.subfamily != "") {
      payload.subfamily = state.sideFilters.subfamily;
    }
    if (state.sideFilters.category && state.sideFilters.category != "") {
      payload.category = state.sideFilters.category;
    }
    if (
      state.sideFilters.manufacturer &&
      state.sideFilters.manufacturer != ""
    ) {
      payload.manufacturer = state.sideFilters.manufacturer;
    }
    if (state.sideFilters.tag_key && state.sideFilters.tag_key != "") {
      payload.tag_key = state.sideFilters.tag_key;
    }
    if (state.sideFilters.tag_value && state.sideFilters.tag_value != "") {
      payload.tag_value = state.sideFilters.tag_value;
    }
    if (state.sideFilters.person && state.sideFilters.person != "") {
      payload.person = state.sideFilters.person;
    }
    let tempAuto = await this.$axios.$post(`/items/autocomplete/`, payload);
    Object.keys(tempAuto.filters).forEach((k) => {
      tempAuto.filters[k] = tempAuto.filters[k].flat(Infinity).map((d) => {
        if (typeof d == "object" && d) {
          return d.name;
        }
        return d;
      });
    });
    await mergePanels(commit, state, tempAuto.filters);
  },
  async distinctSearch({ commit, state, dispatch }, payload) {
    let qTemp = `${payload.query}`;
    let temp = await this.$axios.$get(
      `/items/distinct/${payload.property}?store_id=${
        state.store._id
      }&query=${encodeURIComponent(qTemp)}`
    );
    if (temp) {
      commit("distinct", {
        data: temp,
        property: payload.property,
      });
    } else {
      commit("distinct", {
        data: [],
        property: payload.property,
      });
    }
  },
  async syncSearch({ commit, state }, load) {
    let sidePanel = JSON.parse(JSON.stringify(state.sidePanel));
    let sideFilters = JSON.parse(JSON.stringify(state.sideFilters));
    let key = load.sfilter;
    if (key.includes("sorting")) {
      sideFilters[key] = {
        label: load.ba.label,
        value: load.ba.value,
      };
      commit("sideFilters", sideFilters);
      return;
    }
    if (load.sfilter == "tags.author") {
      key = "persons";
    }
    if (load.init) {
      sidePanel = {};
      sideFilters = {};
    }
    if (key != "page" && !load.sfilter.includes("tags") && key != "q") {
      if (
        sideFilters[key] &&
        (sideFilters[key] || "").split("_or_").includes(load.ba)
      ) {
        sideFilters[key] = (sideFilters[key] || "")
          .split("_or_")
          .filter((s) => s != load.ba && s != "")
          .join("_or_");
        sidePanel[load.sfilter].active = sidePanel[load.sfilter].active.filter(
          (a) => a != load.ba
        );
      } else {
        sideFilters[key] = [...(sideFilters[key] || "").split("_or_"), load.ba]
          .filter((a) => a != "" && a !== undefined)
          .join("_or_");
      }
      sideFilters.page = 1;
    } else if (key != "page" && key != "q") {
      //edw einai to provlima
      if (
        sideFilters["tag_key"] == load.sfilter.replace(/tags\./g, "") &&
        sideFilters["tag_value"] == load.ba
      ) {
        delete sideFilters["tag_key"];
        delete sideFilters["tag_value"];
      } else {
        sideFilters["tag_key"] = load.sfilter.replace(/tags\./g, "");
        sideFilters["tag_value"] = load.ba;
      }
      sideFilters.page = 1;
    } else if (key == "q") {
      sideFilters[key] = (sideFilters[key] || "")
        .split("_or_")
        .filter((s) => s != load.ba && s != "")
        .join("_or_");
    } else {
      sideFilters.page = load.ba;
    }
    commit("sidePanel", sidePanel);
    commit("sideFilters", sideFilters);
  },
  async ajaxSearch({ commit, state, router, dispatch, app }, payload) {
    let search =
      Object.keys(state.sideFilters)
        .filter(
          (k) =>
            k != "sorting" &&
            k != "pages" &&
            k != "page" &&
            k != "total" &&
            state.sideFilters[k]
        )
        .map((k) => {
          return `${k}=${state.sideFilters[k]}`;
        })
        .join("&") +
      `${state.sideFilters.sorting.value.replaceAll("+", "&")}&page=${
        payload.page
      }`;
    let temp = {};
    try {
      temp = await this.$axios.get(
        `${process.env.axiosUrl}/items/store/${state.store.store_id}?store_id=${state.store.store_id}&${search}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      temp = temp.data;
    } catch (error) {
      console.error(error);
    }

    commit("sideFilters", {
      ...state.sideFilters,
      page: payload.page,
    });
    if (payload.replace) {
      commit(
        "items",
        (await $nuxt.$hyperItem.prepareItems(
          ((temp || {}).items || []).docs || []
        )) || []
      );
      return;
    }
    commit("items", [
      ...state.items,
      ...((await $nuxt.$hyperItem.prepareItems(
        ((temp || {}).items || []).docs || []
      )) || []),
    ]);
  },
  async ajaxElasticSearch({ commit, state, router, dispatch, app }, payload) {
    let search =
      Object.keys(state.filters)
        .filter(
          (k) =>
            k != "sorting" &&
            k != "pages" &&
            k != "page" &&
            k != "total" &&
            state.filters[k]
        )
        .map((k) => {
          return `${k}=${encodeURIComponent(state.filters[k])}`;
        })
        .join("&") + `&page=${payload.page}`;
    let temp = {};
    try {
      let pa = state.store.flat_plugins["Project Agora"]?.on;
      if (pa) {
        pa = `&projectAgora=${pa}`;
      }
      console.log(pa);
      temp = await this.$axios.get(
        `${process.env.axiosUrl}/items/sv/store/${state.store.store_id}?store_id=${state.store.store_id}&fullProductPath=${state.store.eshop.fullProductPath}&catalogRoute=${state.store.eshop.catalogRoute}&${search}${pa}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      temp = (temp || {}).data;
    } catch (error) {
      console.error(error);
    }
    commit("filters", {
      ...state.filters,
      page: payload.page,
    });
    $nuxt.$emit("newItems", temp.items.docs);
    if (payload.replace) {
      commit(
        "items",
        (await $nuxt.$hyperItem.prepareItems(
          ((temp || {}).items || []).docs || []
        )) || []
      );
      return;
    }

    let items = [
      ...state.items,
      ...((await $nuxt.$hyperItem.prepareItems(
        ((temp || {}).items || []).docs || []
      )) || []),
    ];
    commit("items", items);
  },
  async distinctFields({ commit, state, router, dispatch }, payload) {
    if (!payload.q || !state.store.eshop.distinctFields) {
      return;
    }
    let temp = {};
    try {
      temp = await this.$axios.post(
        `${process.env.axiosUrl}/items/sv/autocomplete`,
        {
          store_id: state.store.store_id,
          q: payload.q,
          fields: state.store.eshop.distinctFields.split(","),
          size: 100,
        }
      );
      if (!Array.isArray(temp.data)) {
        return [];
      }
      temp = (temp || {}).data;
      return temp;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
    }
  },
  async doSearch({ commit, state, router, dispatch }) {
    if (state.sideFilters.q && state.sideFilters.q.length < 3) {
      return;
    }
    commit("distinct", {
      clear: true,
    });
    if (process.browser) {
      document.activeElement.blur();
    }
    let search = Object.keys(state.sideFilters)
      .filter(
        (k) =>
          k != "pages" &&
          k != "total" &&
          state.sideFilters[k] != "" &&
          k != "sorting"
      )
      .map((k) => {
        if (k.includes("tags.")) {
          return `tag_key=${k.replace(
            /tags./g,
            ""
          )}+tag_value=${encodeURIComponent(state.sideFilters[k])}`;
        }
        return `${k}=${encodeURIComponent(state.sideFilters[k])}`;
      })
      .join("+");
    if (search && !search.includes("limit")) {
      search += `+limit=16`;
    }
    if (state.sideFilters.sorting) {
      search += state.sideFilters.sorting.value;
    }
    $nuxt.$router.push({
      path: `/search/+${search}`,
    });
    return;
  },

  async prepareElements({ commit, state, actions, dispatch, app }, ctx) {
    if (!state.storeMemory[0]) {
      commit("storeMemory", JSON.parse(JSON.stringify(ctx.elements)));
    } else {
      ctx.elements = state.storeMemory;
    }
    if (cartLock) {
      return;
    }
    cartLock = true;

    if (state.store.eshop && state.store.eshop.layout.item) {
      let app = ctx.app;
      delete ctx.app;
      let es = JSON.parse(JSON.stringify(ctx));
      if (es.item) {
        es.elements = es.elements.filter((e) => {
          if (e.restrict_key) {
            return ctx.item[e.restrict_key] == e.restrict_value;
          } else {
            return e;
          }
        });
      }
      es.elements = await app.$hyperElement.populateData({
        page: "item,addtocart",
        elements: es.elements,
        item: ctx.item,
        app,
      });
      // state.store.eshop.elements = es.elements;
      commit("elements", es.elements);
      cartLock = false;
      return;
    }
  },
  async nuxtServerInit(
    { commit, state, dispatch },
    { app, req, res, redirect, store, route, beforeNuxtRender, $config, error }
  ) {
    //redis
    let fullURL = req.headers.host + req.originalUrl;
    if (redirectCheck && !(fullURL + "").includes("/redirect")) {
      try {
        let redirectMe = await this.$axios.$post(
          `${
            process.env.MODE == "DEBUG" ? "http" : "https"
          }://${req.headers.host.replace("www.", "")}/redirect`,
          { origin: req.originalUrl }
        );
        if (redirectMe) {
          redirect(301, redirectMe);
        }
      } catch (e) {
        // console.warn(e);
      }
    }

    commit("isBot", isbot(req.headers["user-agent"]));
    // commit("isBot", true);
    const ua = parser(req.headers["user-agent"]);
    commit("route", route.params);
    commit("url", decodeURIComponent(route.fullPath));
    try {
      let url = new URL(`${req.headers.host}${req.url}`);
      let queries = new URLSearchParams(url.search);
      commit("query", Object.fromEntries([...queries]));
    } catch (error) {}
    const ip = req.connection.remoteAddress || req.socket.remoteAddress;
    if (route.params.q) {
      let temp = {};
      route.params.q.split("&").forEach((p) => {
        let pp = p.split("=");
        temp[pp[0]] = pp[1];
      });
      commit("sideFilters", {
        ...state.sideFilters,
        ...temp,
      });
    }
    let host = req.headers.host;
    commit("host", host);
    let id = "";
    
    //GET SHOPSTER CONFIG JSON
    let sconfig = {};
    try {
      sconfig = JSON.parse(fs.readFileSync("shopster.config.json", "utf-8"));
    } catch (e) {}
    //END SHOPSTER CONFIG
    //GET componentDependencies JSON
    let componentDependencies = {};
    try {
      componentDependencies = JSON.parse(
        fs.readFileSync("prerendered_dependencies.json", "utf-8")
      );
    } catch (e) {}
    //END componentDependencies

    if (!id) {
      if (sconfig.id) {
        id = sconfig.id;
      } else if (process.env.REMOTE_ID) {
        try {
          id = await this.$axios.$post(`/stores/get-id`, {
            url: host.replace("www.", ""),
          });
        } catch (e) {
          console.error(e);
        }
      }
      else {
        let sites = fs.readFileSync("sites.json") || {};
        try {
          host = host.replace("www.", "");
        } catch (e) {}
        JSON.parse(sites).forEach((s) => {
          if (host == s.url) {
            id = s.id;
          }
        });
      }
    }

    console.info(`${req.headers.host}${req.url}=>${id}`);
    if (!id) {
      redirect("/shield#id");
      return;
    }
    let preview = "";
    if (app.$cookies.get("preview")) {
      let pr = decodeURIComponent(app.$cookies.get("preview") || "").split(
        ":::"
      );
      if (pr[1] == id) {
        preview = `&preview=${pr[0]}`;
      }
    }
    if (ua) {
      commit("isMobile", ua.device.type == "mobile");
      commit("isIOS", ua.os.name == "iOS");
      commit("isMozilla", ua.os.browser == "Mozilla");
    } else {
      commit(
        "isMobile",
        req.headers["sec-ch-ua-mobile"] == "?0" ? false : true
      );
    }
    let headers = {
      headers: {
        authorization: `Bearer ${app.$cookies.get("token") || ""}`,
        from: req.url,
      },
    };
    try {
      let res = await this.$axios.$get(
        `/stores/${id}?skipPunishment=true&styleless=true&elements=footer,mobile,navBar,header,addtocart,aux,${preview}`,
        headers
      );
      if (preview && !res.preview) {
        app.$cookies.remove("preview");
        redirect("/");
      }
      if (res.preview) {
        let prev = app.$cookies.get("preview").split(":::");
        prev[2] = res.preview;
        app.$cookies.set("preview", prev.join(":::"));
      }
      if (app.$cookies.get("token")) {
        try {
          let t = await this.$axios.$get(
            `/users/me?store_id=${id}&orders=true&cache=${Date.now()}&favorites=${req.url.includes(
              "/cart"
            )} `,
            headers
          );
          let user = t;
          if (!user.invoice) {
            user.invoice = {};
          }
          if (user.pro) {
            user.isPro = user.pro[id];
          }
          if (!user.extra) {
            user.extra = {};
          }
          if ((user.orders || []).length) {
            user.orders = app.$hyperUser.prepareUserOrders(user.orders);
          }

          commit("user", user);
          commit("token", app.$cookies.get("token"));
        } catch (e) {
          console.error(e);
          app.$cookies.remove("token");
        }
      } else {
        commit("token", "");
      }
      if (app.$cookies.get("cart_id")) {
        try {
          let t = await this.$axios.$get(
            `/cart?store_id=${id}&id=${app.$cookies.get("cart_id")}`,
            headers
          );
          if (t.cart) {
            commit("cart", t.cart);
          } else {
            app.$cookies.remove("cart_id");
          }
        } catch (e) {
          app.$cookies.remove("cart_id");
          console.error(e);
        }
      }
      if (res.id) {
        if (!res.eshop.layout) {
          res.eshop.layout = {};
        }
        if (sconfig.layout) {
          res.eshop.layout = sconfig.layout;
        }
        if (sconfig.favoriteAuth) {
          res.eshop.favoriteAuth = sconfig.favoriteAuth;
        }
        if (!res.eshop.gift) {
          res.eshop.gift = 0;
        }
        res.ssr = true;
        res.ip = ip;
        if (sconfig.pending) {
          res.eshop.ready = "no";
        }
        if (sconfig.maintenance) {
          res.eshop.ready = "maintenance";
        }
        try {
          let rules = await this.$axios.$get(
            `/stores/promos/${id}/public?active=true&full=1`
          );
          res.eshop.rules = rules;
        } catch (error) {
          console.error(error);
        }
        ((res.eshop.cmsPreload || "").split(",") || []).forEach((title) => {
          let d = app.$cms.get({ store_id: res.id, title, limit: 100 });
        });
        if (sconfig.catalogRoute) {
          res.eshop.catalogRoute = sconfig.catalogRoute;
        }
        if (res.eshop.labels) {
          commit("labels", res.eshop.labels);
        }
        res.minShippingFree = 0;
        res.shippings = res.shippings.map((s) => {
          s.options.forEach((so) => {
            s["key_" + so.key] = so.value;
            if (res.minShippingFree < so.value && so.key == "free") {
              res.minShippingFree = so.value;
            }
          });
          return s;
        });
        res.payments = res.payments.map((s) => {
          s.options.forEach((so) => {
            s["key_" + so.key] = so.value;
          });
          return s;
        });
        let filterTreeRequired = [
          "sideMenu_dynamic",
          "sideMenu_dynamic_desaturated",
          "mobile_dynamic_pills",
          "minimal_dynamic_dropmenu",
        ];
        if (
          filterTreeRequired.includes(res.eshop.layout.navBar) ||
          filterTreeRequired.includes(res.eshop.layout.mobile) ||
          route.name == "category-category" ||
          route.name == "categories-category" ||
          (res.eshop.search + "").includes("elastic")
        ) {
          try {
            let tempf = await this.$axios.$get(
              `/items/${
                (res.eshop.search || "").includes("elastic") ? "sv/" : ""
              }filtertree?&store_id=${res._id}&level=2`
            );
            if (Array.isArray(tempf)) {
              commit(
                "filterTree",
                (tempf || []).map((c) => c.business_activity.name)
              );
            } else {
              commit("filterTree", tempf);
            }
          } catch (e) {
            console.error(e);
          }
        }
        res.inited = true;
        commit("actual", actualRender(req, res, sconfig.layout));
        if (sconfig.overrides) {
          res.eshop.layoutOverrides = sconfig.overrides;
        }
        if (res.eshop.elements) {
          res.eshop.elements =
            (await app.$hyperElement.populateData({
              elements: res.eshop.elements,
              page: "footer,mobile,navBar,header",
              app,
            })) || [];
        }
        try {
          if ((res.eshop.hooks || {}).prestore) {
            eval(res.eshop.hooks.prestore);
          }
        } catch (e) {
          console.error(e);
        }
        commit("store", res);
        commit("store_id", id);
        if ((sconfig || {}).attribution) {
          commit("attribution", sconfig.attribution);
        } else if (res.eshop.attribution) {
          commit("attribution", res.eshop.attribution);
        }
        if (false && Object.keys(componentDependencies)) {
          let pages = [];
          let elements = Object.keys(componentDependencies).reduce((acc, _) => {
            acc.push(componentDependencies[_]);
            if (!pages.includes(componentDependencies[_].page)) {
              pages.push(componentDependencies[_].page);
            }
            return acc;
          }, []);
          for (const p of pages) {
            await app.$hyperElement.populateData(
              {
                elements: elements.filter((e) => e.page == p),
                page: p,
                app,
              },
              true
            );
          }
        }
      }
    } catch (e) {
      console.error(e.response);
      console.warn(e.stack);
      console.error("catchInit", e);
      if (process.server) {
        res.statusCode = 500; // Modify status code if needed
      }
      return error({ statusCode: 500, message: "OOOooops" });
      // redirect("/shield#error");
    }
    beforeNuxtRender(async ({ nuxtState }) => {
      let host = req.headers.host + route.fullPath;
      if ((nuxtState.error || {}).statusCode == 404) {
        try {
          let t = await this.$axios.$get(`/stores/redirect`, {
            headers: {
              shopster: host,
            },
          });
          console.warn(t);
        } catch (error) {
          console.error(error);
        }
      }
    });
  },
};
